import React from "react";
import { ReduxAsyncConnect } from "redux-connect";

import routes from "./config/routes";

const App = () => {
	return <ReduxAsyncConnect routes={routes} />;
};

export default App;
