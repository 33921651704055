// ** Third Party Components
import styled, { css } from "styled-components";

// #####################################################

export const StyledIconContainer = styled.span`
	display: inline-block;
	${({ iconSize }) =>
		iconSize &&
		css`
			font-size: ${iconSize};
		`}
`;

// #####################################################
