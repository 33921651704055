// ** Third Party Components
import styled from "styled-components";

// #####################################################

export const StyledFallbackContainer = styled.div`
	display: flex;
	align-self: center;
	min-height: 100vh;
	font-family: Arial, Helvetica, sans-serif;
	font-size: 14px;
	line-height: 1.5;
`;

// #####################################################
