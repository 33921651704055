export const processFakeUserData = (action) => {
	const partners = {
		...(action.result.sex === "couple"
			? {
					birthday: [
						action.result.partner_1.birthday,
						action.result.partner_2.birthday,
					],
					sex: [
						action.result.partner_1.sex,
						action.result.partner_2.sex,
					],
			  }
			: {}),
		...(action.result.sex !== "couple"
			? {
					birthday: [action.result.user_data.birthday],
					sex: [action.result.sex],
			  }
			: {}),
	};
	return {
		id: action.result.id,
		avatar: action.result.avatar,
		login: action.result.login,
		city: action.result.city,
		region: action.result.region,
		country: action.result.country,
		sex: action.result.sex ?? null,
		cityName: action.result.city,
		partners,
	};
};
