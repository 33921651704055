// ** React Imports
import { useState } from "react";

// ** Custom Components
import Icon from "components/global/Icon";
import Text from "components/typography/Text";

// ** Styled Components
import {
	StyledButtonText,
	StyledIconContainer,
	StyledButtonContent,
} from "../styles";

// ** SVG Static Imports
import { ReactComponent as SolidCaretUp } from "resources/icons/solid/caret-up.svg";
import { ReactComponent as SolidCaretDown } from "resources/icons/solid/caret-down.svg";

// #####################################################

const BtnContent = ({
	active,
	textLeft,
	textContent,
	iconType,
	iconTypeActive,
	icon,
	iconOnly,
	iconSize,
	iconComponent,
	dropdown,
	svgIconComponent,
	svgIconComponentActive,
	alwaysLight,
	alignItemsStart,
	noPointerEffect,
}) => {
	const [finalIconType, setFinalIconType] = useState(iconType || undefined);

	const IconComponent = iconComponent || Icon;

	return (
		<>
			<StyledButtonContent
				noPointerEffect={noPointerEffect}
				textLeft={textLeft}
				onMouseEnter={() =>
					iconTypeActive && setFinalIconType(iconTypeActive)
				}
				onMouseLeave={() => setFinalIconType(iconType || undefined)}
				alignItemsStart={alignItemsStart}
			>
				{icon && (
					<StyledIconContainer iconSize={iconSize}>
						<IconComponent
							svgIconComponent={
								active
									? svgIconComponentActive
									: svgIconComponent
							}
							type={active ? iconTypeActive : finalIconType}
						/>
					</StyledIconContainer>
				)}
				{iconOnly !== true && (
					<StyledButtonText
						icon={icon}
						textLeft={textLeft}
						dropdown={dropdown}
					>
						{textContent}
					</StyledButtonText>
				)}
				{dropdown && (
					<Text style={{ fontSize: "0.85em" }}>
						<Icon
							svgIconComponent={
								active ? SolidCaretUp : SolidCaretDown
							}
							alwaysLight={alwaysLight}
						>
							{active ? "caret-up" : "caret-down"}
						</Icon>
					</Text>
				)}
			</StyledButtonContent>
		</>
	);
};

export default BtnContent;
