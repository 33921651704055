import { applyMiddleware, compose, createStore } from "redux";
import thunk from "redux-thunk";
import api from "helpers/ApiClient";
import deepmerge from "deepmerge";
import { window } from "ssr-window";
import config from "configuration";
import syncReduxWithLocalstorage from "../libs/sync-redux-with-localstorage";
import createClientMiddleware from "./middleware/clientMiddleware";
import createRootReducer from "./reducer";

/**
 * Singleton instance of vendor with redux store object for global reference to the redux store getState or dispatch.
 */
const vendor = {
	store: null,
	history: null,
};

/**
 * Helper function that always returns a valid JS object that was readed from the reduxLocalStorage.
 * @returns
 */
function getReduxLocalState() {
	if (typeof window === "undefined" || !window.localStorage) {
		return null;
	}

	const encodedState = window.localStorage.getItem("reduxLocalStorage");

	if (encodedState == null) {
		return null;
	}

	return JSON.parse(encodedState);
}

/**
 * Helper function that returns compositor, either from the window on the browser or default compositor from redux.
 * @returns
 */
function getCompositor() {
	return (
		(config.MODE.__DEVELOPMENT__ &&
			window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
		compose
	);
}

/**
 * Creating redux store, with history that is required, and optional api client.
 * @param {*} state
 * @param {*} config
 * @returns
 */
function createStoreFromState(state, config = {}) {
	if (config.history == null) {
		throw new Error(
			`store/vendor requires history in config at createStoreFromState(..., [config])`
		);
	}

	const middleware = [
		createClientMiddleware(config.client || api),
		thunk,
		syncReduxWithLocalstorage({
			global: {
				user: true,
			},
		}),
	];

	const reduxLocalStorageState = getReduxLocalState();
	const userId = reduxLocalStorageState?.global?.user?.id;

	const finalState =
		reduxLocalStorageState && userId
			? deepmerge(state, getReduxLocalState())
			: state;

	/**
	 * Creating store with state as merged state with redux local state.
	 */
	return createStore(
		createRootReducer(config.history),
		finalState,
		getCompositor()(applyMiddleware(...middleware))
	);
}

/**
 * Creating redux store server side.
 * @param {*} state
 * @param {*} config
 * @returns
 */
function createServerStoreFromState(state, config = {}) {
	return createStoreFromState(state, config);
}

/**
 * Creating redux store client side.
 * @param {*} state
 * @param {*} config
 * @returns
 */
function createClientStoreFromState(state, config = {}) {
	vendor.history = config.history;

	return (vendor.store = createStoreFromState(state, config));
}

export { vendor, createServerStoreFromState, createClientStoreFromState };
