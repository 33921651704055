"use client";

// ** React Imports
import { memo } from "react";

// ** Third Party Components
import { useTranslation } from "react-i18next";
import { ErrorBoundary } from "react-error-boundary";

// ** Styles Imports
import { ButtonPrimary } from "components/layout/Button/ButtonPrimary";
import { StyledFallbackContainer } from "./styles/FallbackContainer.styled";
import { StyledMessageContainer } from "./styles/MessageContainer.styled";

// ** Utils & Helpers
import api from "helpers/ApiClient";

// ** Icons Imports
import FallbackLoader from "components/icons/FallbackLoader";

// #####################################################

const SEND_API_ERROR = true;
const FALLBACK_RELOAD_DELAY_APPLICATION = 10 * 1000;
const FALLBACK_RELOAD_DELAY_MISSING_CHUNK = 3 * 1000;

// #####################################################

const Fallback = ({ children }) => {
	const { t } = useTranslation("common");

	const isDev = process.env.NODE_ENV === "development";

	const isAfterRefresh = window.location.hash === "#refresh";

	const reloadPage = () => {
		if (typeof window !== "undefined") {
			if (!isAfterRefresh) {
				window.location = window.location + "#refresh";
			}

			window.location.reload();
		}
	};

	const fallbackRender = ({ error }) => {
		const missingChunkType =
			error.type === "missing" ||
			error.name === "ChunkLoadError" ||
			error.code == "CSS_CHUNK_LOAD_FAILED";
		return (
			<StyledFallbackContainer>
				<StyledMessageContainer>
					<p>
						{missingChunkType
							? t("fallback.update.message")
							: t("errors.commonErrorCatch")}
					</p>
					<FallbackLoader />
					<p>{t("fallback.update.reload")}</p>
					<ButtonPrimary
						my={4}
						label={t("errors.refresh")}
						type="button"
						onClick={reloadPage}
					/>
				</StyledMessageContainer>
			</StyledFallbackContainer>
		);
	};

	const handleError = (error) => {
		if (
			error.type === "missing" ||
			error.name === "ChunkLoadError" ||
			error.code == "CSS_CHUNK_LOAD_FAILED"
		) {
			setTimeout(() => {
				if (typeof window !== "undefined") {
					window.location.reload();
				}
			}, FALLBACK_RELOAD_DELAY_MISSING_CHUNK);
		} else {
			if (!isDev) {
				if (SEND_API_ERROR && !isAfterRefresh) {
					const data = {
						route: window?.location?.href ?? "FAILED_TO_GET_ROUTE",
						error: error?.message ?? "UNKNOWN ERROR",
						error_stack: error?.stack?.toString() ?? "",
						from: "Fallback",
						error_full: JSON.stringify(error),
					};
					api.post("/system/react-error", data);
				}

				setTimeout(() => {
					reloadPage();
				}, FALLBACK_RELOAD_DELAY_APPLICATION);
			}
		}
	};

	return (
		<ErrorBoundary fallbackRender={fallbackRender} onError={handleError}>
			{children}
		</ErrorBoundary>
	);
};

export default memo(Fallback);
