/* eslint-disable prefer-template */
/* eslint-disable no-case-declarations */
import defaults from "../../defaults";

const GET_VIDEOS = "userpanel/videos/GET_VIDEOS";
const GET_VIDEOS_SUCCESS = "userpanel/videos/GET_VIDEOS_SUCCESS";
const GET_VIDEOS_FAIL = "userpanel/videos/GET_VIDEOS_FAIL";

const REMOVE_VIDEOS = "userpanel/videos/REMOVE_VIDEOS";
const REMOVE_VIDEOS_SUCCESS = "userpanel/videos/REMOVE_VIDEOS_SUCCESS";
const REMOVE_VIDEOS_FAIL = "userpanel/videos/REMOVE_VIDEOS_FAIL";

const GET_VIDEO_INFO = "userpanel/videos/GET_VIDEO_INFO";
const GET_VIDEO_INFO_SUCCESS = "userpanel/videos/GET_VIDEO_INFO_SUCCESS";
const GET_VIDEO_INFO_FAIL = "userpanel/videos/GET_VIDEO_INFO_FAIL";

const GET_VIDEO_COMMENTS = "userpanel/videos/GET_VIDEO_COMMENTS";
const GET_VIDEO_COMMENTS_SUCCESS =
	"userpanel/videos/GET_VIDEO_COMMENTS_SUCCESS";
const GET_VIDEO_COMMENTS_FAIL = "userpanel/videos/GET_VIDEO_COMMENTS_FAIL";

const GET_VIDEO_LIKES = "userpanel/videos/GET_VIDEO_LIKES";
const GET_VIDEO_LIKES_SUCCESS = "userpanel/videos/GET_VIDEO_LIKES_SUCCESS";
const GET_VIDEO_LIKES_FAIL = "userpanel/videos/GET_VIDEO_LIKES_FAIL";

const initialState = defaults.userpanel.videos;

export default function reducer(state = initialState, action = {}) {
	switch (action.type) {
		case GET_VIDEOS:
			return {
				...state,
				videos: {
					...state.videos,
					loading: true,
				},
			};
		case GET_VIDEOS_SUCCESS:
			return {
				...state,
				videos: {
					...state.videos,
					loading: false,
					error: false,
					success: true,
					videos: action.result.data?.videos ?? [],
					pagination: action.result.data?.pagination ?? {},
				},
			};
		case GET_VIDEOS_FAIL:
			return {
				...state,
				videos: {
					...state.videos,
					loading: false,
					error: true,
					success: false,
				},
			};
		case GET_VIDEO_INFO:
			return {
				...state,
				editVideo: {
					...state.editVideo,
					info: {
						...initialState.editVideo.info,
						loading: true,
					},
					likes: {
						...initialState.editVideo.likes,
						loading: true,
					},
					comments: {
						...initialState.editVideo.comments,
						loading: true,
					},
				},
			};
		case GET_VIDEO_INFO_SUCCESS:
			return {
				...state,
				editVideo: {
					...state.editVideo,
					info: {
						...state.editVideo.info,
						loading: false,
						loaded: true,
						success: true,
						accepted: action.result.data.accepted,
						accepted_description:
							action.result.data.accepted_description,
						video: action.result.data?.video || false,
						poster: action.result.data?.thumbs?.poster || false,
						description: action.result.data.description,
						description_raw: action.result.data.description_raw,
						description_edit:
							action.result.data?.description_edit || [],
						album: action.result.data.album,
						album_new: action.result?.data?.album_new,
						album_name: action.result.data.album_name,
						private: action.result.data.private,
						ready: action.result.data?.ready,
						errorConvert: action.result.data?.error,
						videos_in_album: action.result.data.videos_in_album,
						media_in_album: action.result.data?.media_in_album || 0,
						date_add: action.result.data.date_add,
						count_like: action.result.data.count_like,
						count_comment: action.result.data.count_comment,
						i_liked: action.result.data?.i_liked || false,
						i_commented: action.result.data?.i_comment || false,
					},
					likes: {
						...state.editVideo.likes,
						loading: false,
						loaded: true,
						success: true,
						...action.result.data.likes,
					},
					comments: {
						...state.editVideo.comments,
						loading: false,
						loaded: true,
						success: true,
						...action.result.data.comments,
					},
				},
			};
		case GET_VIDEO_INFO_FAIL:
			return {
				...state,
				editVideo: {
					...state.editVideo,
					info: {
						...state.editVideo.info,
						loading: false,
						loaded: true,
						error: true,
					},
					likes: {
						...state.editVideo.likes,
						loading: false,
						loaded: true,
						error: true,
					},
					comments: {
						...state.editVideo.comments,
						loading: false,
						loaded: true,
						error: true,
					},
				},
			};
		case GET_VIDEO_COMMENTS:
			return {
				...state,
				editVideo: {
					...state.editVideo,
					comments: {
						...state.editVideo.comments,
						loading: true,
					},
				},
			};
		case GET_VIDEO_COMMENTS_SUCCESS:
			return {
				...state,
				editVideo: {
					...state.editVideo,
					comments: {
						...state.editVideo.comments,
						loading: false,
						loaded: true,
						success: true,
						comments: action.result.data.comments,
					},
				},
			};
		case GET_VIDEO_COMMENTS_FAIL:
			return {
				...state,
				editVideo: {
					...state.editVideo,
					comments: {
						...state.editVideo.comments,
						loading: false,
						loaded: true,
						error: true,
					},
				},
			};
		case GET_VIDEO_LIKES:
			return {
				...state,
				editVideo: {
					...state.editVideo,
					likes: {
						...state.editVideo.likes,
						loading: true,
					},
				},
			};
		case GET_VIDEO_LIKES_SUCCESS:
			return {
				...state,
				editVideo: {
					...state.editVideo,
					likes: {
						...state.editVideo.likes,
						loading: false,
						loaded: true,
						success: true,
						likes: action.result.data.likes,
					},
				},
			};
		case GET_VIDEO_LIKES_FAIL:
			return {
				...state,
				editVideo: {
					...state.editVideo,
					likes: {
						...state.editVideo.likes,
						loading: false,
						loaded: true,
						error: true,
					},
				},
			};
		default:
			return state;
	}
}

/**
 * Zwraca listing filmow
 * @api GET getVideos
 * @param {Number} page numer strony
 */
export function getVideos(page) {
	return {
		types: [GET_VIDEOS, GET_VIDEOS_SUCCESS, GET_VIDEOS_FAIL],
		promise: (client) =>
			client.get(`/video/panel`, {
				params: {
					type: "any",
					addEmpty: true,
					page,
				},
			}),
	};
}

/**
 * Usuwa video z listy filmow użytkownika
 * @api DELETE deleteVideosMultiple
 * @param {Array.<Number>} ids tablica identyfikatorów filmow
 */
export function removeVideos(ids) {
	return {
		types: [REMOVE_VIDEOS, REMOVE_VIDEOS_SUCCESS, REMOVE_VIDEOS_FAIL],
		promise: (client) =>
			client.delete(`/video/multiple`, {
				params: {
					ids: ids.join(","),
				},
			}),
		data: ids,
	};
}

/**
 * Pobiera informacje o filmie
 * @api GET getVideoById
 * @param {Number} videoId id filmowia
 * @param {Number} userId id usera
 */
export function getVideoInfo(videoId, userId) {
	return {
		types: [GET_VIDEO_INFO, GET_VIDEO_INFO_SUCCESS, GET_VIDEO_INFO_FAIL],
		promise: (client) =>
			client.get(`/video/panel/single`, {
				params: { id: videoId, user: userId, additional: true },
			}),
	};
}

/**
 * Pobiera liste polubien do filmu
 * @api GET getLikesOnFile
 * @param {Number} videoId id filmu
 * @param {Number} page numer strony
 */
export function getVideoLikes(userId, videoId, page) {
	return {
		types: [GET_VIDEO_LIKES, GET_VIDEO_LIKES_SUCCESS, GET_VIDEO_LIKES_FAIL],
		promise: (client) =>
			client.get(`/like/file`, {
				params: {
					id: videoId,
					user: userId,
					type: "video",
					page,
				},
			}),
	};
}

/**
 * Pobiera liste komentarzy do filmu
 * @api GET getCommentsOnFile
 * @param {Number} videoId id filmu
 * @param {Number} page numer strony
 */
export function getVideoComments(userId, videoId, page) {
	return {
		types: [
			GET_VIDEO_COMMENTS,
			GET_VIDEO_COMMENTS_SUCCESS,
			GET_VIDEO_COMMENTS_FAIL,
		],
		promise: (client) =>
			client.get(`/comment/file`, {
				params: {
					id: videoId,
					user: userId,
					type: "video",
					page,
				},
			}),
	};
}
