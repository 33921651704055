import defaults from "../defaults";

const GET_CONVERSATIONS = "message/GET_CONVERSATIONS";
const GET_CONVERSATIONS_SUCCESS = "messages/GET_CONVERSATIONS_SUCCESS";
const GET_CONVERSATIONS_FAIL = "messages/GET_CONVERSATIONS_FAIL";

const REMOVE_CONVERSATIONS = "messages/REMOVE_CONVERSATIONS";
const REMOVE_CONVERSATIONS_SUCCESS = "messages/REMOVE_CONVERSATIONS_SUCCESS";
const REMOVE_CONVERSATIONS_FAIL = "messages/REMOVE_CONVERSATIONS_FAIL";

const GET_NEW_CONVERSATIONS = "messages/GET_NEW_CONVERSATIONS";
const GET_NEW_CONVERSATIONS_SUCCESS = "messages/GET_NEW_CONVERSATIONS_SUCCESS";
const GET_NEW_CONVERSATIONS_FAIL = "messages/GET_NEW_CONVERSATIONS_FAIL";

const GET_SENT_CONVERSATIONS = "messages/GET_SENT_CONVERSATIONS";
const GET_SENT_CONVERSATIONS_SUCCESS =
	"messages/GET_SENT_CONVERSATIONS_SUCCESS";
const GET_SENT_CONVERSATIONS_FAIL = "messages/GET_SENT_CONVERSATIONS_FAIL";

const GET_MESSAGE_NOTIFICATION_STATUS =
	"messages/GET_MESSAGE_NOTIFICATION_STATUS";
const GET_MESSAGE_NOTIFICATION_STATUS_SUCCESS =
	"messages/GET_MESSAGE_NOTIFICATION_STATUS_SUCCESS";
const GET_MESSAGE_NOTIFICATION_STATUS_FAIL =
	"messages/GET_MESSAGE_NOTIFICATION_STATUS_FAIL";

const initialState = defaults.messages;

export default function reducer(state = initialState, action = {}) {
	switch (action.type) {
		case GET_CONVERSATIONS:
			return {
				...state,
				allConversations: {
					...state.allConversations,
					loading: true,
				},
			};
		case GET_CONVERSATIONS_SUCCESS:
			return {
				...state,
				amount: {
					new: action.result.data?.amount.new ?? state.amount.new,
					dialog:
						action.result.data?.amount.dialog ??
						state.amount.dialog,
				},
				pushNotifications: {
					messages: action.result.data?.settings.messages,
				},
				allConversations: {
					...state.allConversations,
					loading: false,
					loaded: true,
					total: action.result.data?.total ?? 0,
					data: action.result.data?.conversations ?? [],
					pagination: {
						totalpages:
							action.result.data?.pagination?.totalpages ?? 1,
						currentpage:
							action.result.data?.pagination?.currentpage ?? 1,
					},
				},
			};
		case GET_CONVERSATIONS_FAIL:
			return {
				...state,
				allConversations: {
					...state.allConversations,
					loading: false,
					loaded: true,
				},
			};
		case REMOVE_CONVERSATIONS:
			return {
				...state,
				[action.type]: {
					...state[action.type],
					whileRemoving: true,
				},
			};
		case REMOVE_CONVERSATIONS_SUCCESS:
			return {
				...state,
				[action.type]: {
					...state[action.type],
					whileRemoving: false,
				},
			};
		case REMOVE_CONVERSATIONS_FAIL:
			return {
				...state,
				[action.type]: {
					...state[action.type],
					whileRemoving: false,
				},
			};
		case GET_NEW_CONVERSATIONS:
			return {
				...state,
				newConversations: {
					...state.newConversations,
					loading: true,
				},
			};
		case GET_NEW_CONVERSATIONS_SUCCESS:
			return {
				...state,
				amount: {
					new: action.result.data?.amount.new ?? state.amount.new,
					dialog:
						action.result.data?.amount.dialog ??
						state.amount.dialog,
				},
				pushNotifications: {
					messages: action.result.data?.settings.messages,
				},
				newConversations: {
					...state.newConversations,
					loading: false,
					loaded: true,
					total: action.result.data?.total ?? 0,
					data: action.result.data?.conversations ?? [],
					pagination: {
						totalpages:
							action.result.data?.pagination?.totalpages ?? 1,
						currentpage:
							action.result.data?.pagination?.currentpage ?? 1,
					},
				},
			};
		case GET_NEW_CONVERSATIONS_FAIL:
			return {
				...state,
				sentConversations: {
					...state.sentConversations,
					loading: false,
					loaded: true,
				},
			};
		case GET_SENT_CONVERSATIONS:
			return {
				...state,
				sentConversations: {
					...state.sentConversations,
					loading: true,
				},
			};
		case GET_SENT_CONVERSATIONS_SUCCESS:
			return {
				...state,
				amount: {
					new: action.result.data?.amount.new ?? state.amount.new,
					dialog:
						action.result.data?.amount.dialog ??
						state.amount.dialog,
				},
				pushNotifications: {
					messages: action.result.data?.settings.messages,
				},
				sentConversations: {
					...state.sentConversations,
					loading: false,
					loaded: true,
					total: action.result.data?.total ?? 0,
					data: action.result.data?.conversations ?? [],
					pagination: {
						totalpages:
							action.result.data?.pagination?.totalpages ?? 1,
						currentpage:
							action.result.data?.pagination?.currentpage ?? 1,
					},
				},
			};
		case GET_SENT_CONVERSATIONS_FAIL:
			return {
				...state,
				sentConversations: {
					...state.sentConversations,
					loading: false,
					loaded: true,
				},
			};

		case GET_MESSAGE_NOTIFICATION_STATUS:
			return {
				...state,
			};

		case GET_MESSAGE_NOTIFICATION_STATUS_SUCCESS:
			return {
				...state,
				pushNotifications: {
					messages: action?.result?.data?.settings?.messages || false,
				},
			};

		case GET_MESSAGE_NOTIFICATION_STATUS_FAIL:
			return {
				...state,
			};

		default:
			return state;
	}
}

/**
 * Zwraca listing rozmow wskazanym rodzaju
 * @api GET getConversations
 * @param {Number} page numer strony
 */
export function getConversations({
	page = 1,
	filter = null,
	id_fcm,
	hash_fcm,
}) {
	return {
		types: [
			GET_CONVERSATIONS,
			GET_CONVERSATIONS_SUCCESS,
			GET_CONVERSATIONS_FAIL,
		],
		promise: (client) =>
			client.get(`/message/conversations`, {
				params: { page, filter, type: "dialog", id_fcm, hash_fcm },
				headers: {
					Accept: "application/api.1.1+json",
				},
			}),
	};
}

export function getMessageNotificationsStatus({
	firebaseId,
	firebaseHash,
} = {}) {
	return {
		types: [
			GET_MESSAGE_NOTIFICATION_STATUS,
			GET_MESSAGE_NOTIFICATION_STATUS_SUCCESS,
			GET_MESSAGE_NOTIFICATION_STATUS_FAIL,
		],
		promise: (client) =>
			client.get(`/setting/fcm`, {
				params: {
					id: firebaseId || undefined,
					hash: firebaseHash || undefined,
				},
			}),
	};
}

/**
 * Usuwa konwersacje z listy
 * @api DELETE deleteConversationMultiple
 * @param {Array.<Number>} ids tablica identyfikatorow konwersacji
 * @param {String} ids typ zakladki [conversations,newConversations,sentConversations]
 */
export function removeConversation(ids, type) {
	return {
		types: [
			REMOVE_CONVERSATIONS,
			REMOVE_CONVERSATIONS_SUCCESS,
			REMOVE_CONVERSATIONS_FAIL,
		],
		promise: (client) =>
			client.delete(`/message/conversation/multiple`, {
				params: {
					ids: ids.join(","),
				},
				headers: {
					Accept: "application/api.1.1+json",
				},
			}),
		type,
	};
}

/**
 * Zwraca listing nowych rozmow
 * @api GET getConversations
 * @param {Number} page numer strony
 */
export function getNewConversations({
	page = 1,
	filter = null,
	id_fcm,
	hash_fcm,
}) {
	return {
		types: [
			GET_NEW_CONVERSATIONS,
			GET_NEW_CONVERSATIONS_SUCCESS,
			GET_NEW_CONVERSATIONS_FAIL,
		],
		promise: (client) =>
			client.get(`/message/conversations`, {
				params: { page, filter, type: "new", id_fcm, hash_fcm },
				headers: {
					Accept: "application/api.1.1+json",
				},
			}),
	};
}

/**
 * Zwraca listing wyslanych wiadomosci
 * @api GET getConversations
 * @param {Number} page numer strony
 */
export function getSentConversations({
	page = 1,
	filter = null,
	id_fcm,
	hash_fcm,
}) {
	return {
		types: [
			GET_SENT_CONVERSATIONS,
			GET_SENT_CONVERSATIONS_SUCCESS,
			GET_SENT_CONVERSATIONS_FAIL,
		],
		promise: (client) =>
			client.get(`/message/conversations`, {
				params: { page, filter, type: "send", id_fcm, hash_fcm },
				headers: {
					Accept: "application/api.1.1+json",
				},
			}),
	};
}
