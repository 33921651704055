/* eslint-disable no-case-declarations */
import defaults from "../../defaults";

const GET_PREFERENCES = "userpanel/settings/GET_PREFERENCES";
const GET_PREFERENCES_SUCCESS = "userpanel/settings/GET_PREFERENCES_SUCCESS";
const GET_PREFERENCES_FAIL = "userpanel/settings/GET_PREFERENCES_FAIL";

const DELETE = "userpanel/settings/DELETE";
export const DELETE_SUCCESS = "userpanel/settings/DELETE_SUCCESS";
const DELETE_FAIL = "userpanel/settings/DELETE_FAIL";

const CHANGE_PASSWORD = "userpanel/settings/CHANGE_PASSWORD";
const CHANGE_PASSWORD_SUCCESS = "userpanel/settings/CHANGE_PASSWORD_SUCCESS";
const CHANGE_PASSWORD_FAIL = "userpanel/settings/CHANGE_PASSWORD_FAIL";

const CHANGE_EMAIL = "userpanel/settings/CHANGE_EMAIL";
const CHANGE_EMAIL_SUCCESS = "userpanel/settings/CHANGE_EMAIL_SUCCESS";
const CHANGE_EMAIL_FAIL = "userpanel/settings/CHANGE_EMAIL_FAIL";

const CHANGE_EMAIL_VERYFICATION =
	"userpanel/settings/CHANGE_EMAIL_VERYFICATION";
const CHANGE_EMAIL_VERYFICATION_SUCCESS =
	"userpanel/settings/CHANGE_EMAIL_VERYFICATION_SUCCESS";
const CHANGE_EMAIL_VERYFICATION_FAIL =
	"userpanel/settings/CHANGE_EMAIL_VERYFICATION_FAIL";

const GET_BLOCKED_USERS = "userpanel/settings/GET_BLOCKED_USERS";
const GET_BLOCKED_USERS_SUCCESS =
	"userpanel/settings/GET_BLOCKED_USERS_SUCCESS";
const GET_BLOCKED_USERS_FAIL = "userpanel/settings/GET_BLOCKED_USERS_FAIL";

const initialState = defaults.userpanel.settings;

export default function reducer(state = initialState, action = {}) {
	switch (action.type) {
		case GET_PREFERENCES:
			return {
				...state,
				preferences: {
					...state.preferences,
					loading: true,
				},
			};
		case GET_PREFERENCES_SUCCESS:
			const { settingsGeneral, settingsPush } = action.result.data;

			return {
				...state,
				preferences: {
					...state.preferences,
					loading: false,
					loaded: true,
					searchSex: settingsGeneral.response.settings.search_sex,
					allowLike: settingsGeneral.response.settings.like,
					allowComment: settingsGeneral.response.settings.comment,
					allowInvite: settingsGeneral.response.settings.invite,
					allowConversations:
						settingsGeneral.response.settings.message,
					allowFollowing: settingsGeneral.response.settings.follow,
					allowVisitorsWhoIamSearch:
						settingsGeneral.response.settings.accept_sex,
					notificationsPlaySounds:
						settingsGeneral.response.settings.sound,
					notificationsDailySummary:
						settingsGeneral.response.settings.email_day,
					pushMessagesNotifications:
						settingsPush?.response?.settings?.messages || false,
					pushOtherNotifications:
						settingsPush?.response?.settings?.other || false,
					privateFriends:
						settingsGeneral.response.settings?.privacy?.friends ||
						false,
					privateFollowers:
						settingsGeneral.response.settings?.privacy?.follows ||
						false,
					verifyVipShowMyStatus: {
						enabled:
							settingsGeneral.response.settings.show_status_vip
								.enabled,
						status: settingsGeneral.response.settings
							.show_status_vip.status,
					},
					verifyVipShowMyAge: {
						enabled:
							settingsGeneral.response.settings.show_age.enabled,
						status: settingsGeneral.response.settings.show_age
							.status,
					},
					verifyVipRenewVipAutomatically: {
						enabled:
							settingsGeneral.response.settings.renew_vip.enabled,
						status: settingsGeneral.response.settings.renew_vip
							.status,
					},
				},
			};
		case GET_PREFERENCES_FAIL:
			return {
				...state,
				preferences: {
					...initialState.preferences,
					loaded: false,
					loading: false,
				},
			};
		case DELETE:
			return {
				...state,
				whileDeletingAccount: true,
			};
		case DELETE_SUCCESS:
			return {
				...state,
				whileDeletingAccount: false,
			};
		case DELETE_FAIL:
			return {
				...state,
				whileDeletingAccount: false,
			};
		case CHANGE_PASSWORD:
			return {
				...state,
				whilePasswordChanging: true,
			};
		case CHANGE_PASSWORD_SUCCESS:
			return {
				...state,
				whilePasswordChanging: false,
			};
		case CHANGE_PASSWORD_FAIL:
			return {
				...state,
				whilePasswordChanging: false,
			};
		case CHANGE_EMAIL:
			return {
				...state,
				whileEmailChanging: true,
			};
		case CHANGE_EMAIL_SUCCESS:
			return {
				...state,
				whileEmailChanging: false,
			};
		case CHANGE_EMAIL_FAIL:
			return {
				...state,
				whileEmailChanging: false,
			};
		case CHANGE_EMAIL_VERYFICATION:
			return {
				...state,
				whileEmailChanging: true,
			};
		case CHANGE_EMAIL_VERYFICATION_SUCCESS:
			return {
				...state,
				whileEmailChanging: false,
			};
		case CHANGE_EMAIL_VERYFICATION_FAIL:
			return {
				...state,
				whileEmailChanging: false,
			};
		case GET_BLOCKED_USERS:
			return {
				...state,
				loading: true,
				blocked: {
					...state.blocked,
					loading: true,
				},
			};
		case GET_BLOCKED_USERS_SUCCESS:
			return {
				...state,
				blocked: {
					...state.blocked,
					loading: false,
					loaded: true,
					users: action.result.data?.users ?? [],
					pagination: action.result.data?.pagination ?? 1,
				},
			};
		case GET_BLOCKED_USERS_FAIL:
			return {
				...state,
				blocked: {
					...initialState.blocked,
					loading: false,
					loaded: true,
				},
			};
		default:
			return state;
	}
}

/**
 * Zwraca ustawienia
 */
export function getPreferences({ firebaseId, firebaseHash } = {}) {
	return {
		types: [GET_PREFERENCES, GET_PREFERENCES_SUCCESS, GET_PREFERENCES_FAIL],
		promise: (client) =>
			client.post(
				"/merge/bulk",
				JSON.stringify([
					{
						key: "settingsGeneral",
						method: "GET",
						url: `/setting/general`,
						args: {},
					},
					{
						key: "settingsPush",
						method: "GET",
						url: `/setting/fcm`,
						args: {
							id: firebaseId || undefined,
							hash: firebaseHash || undefined,
						},
					},
				])
			),
	};
}

/**
 * Kasuje konto
 * @api deleteUser
 * @param {String} password hasło do konta
 */
export function deleteAccount(password) {
	return {
		types: [DELETE, DELETE_SUCCESS, DELETE_FAIL],
		promise: (client) =>
			client.put(`/setting/delete/web`, {
				password,
			}),
	};
}

/**
 * Zmienia hasło do konta
 * @api changePassword
 * @param {String} currentPassword obecne hasło
 * @param {String} newPassword nowe hasło
 */
export function changePassword(currentPassword, newPassword) {
	return {
		types: [CHANGE_PASSWORD, CHANGE_PASSWORD_SUCCESS, CHANGE_PASSWORD_FAIL],
		promise: (client) =>
			client.put(`/setting/change-password/web`, {
				old: currentPassword,
				new: newPassword,
			}),
	};
}

/**
 * Zmienia adresu email do konta
 * @api changeEmail
 * @param {String} email nowy adres email
 */
export function changeEmail(email, password) {
	return {
		types: [CHANGE_EMAIL, CHANGE_EMAIL_SUCCESS, CHANGE_EMAIL_FAIL],
		promise: (client) =>
			client.put(`/setting/change-email/secure/web`, {
				email,
				password,
			}),
	};
}

/**
 * Zmienia adresu email do konta potwierdzenie
 * @api changeEmailVerify
 * @param {Integer} id numer zmiany emaila
 * @param {String} hash suma kontrolna
 */
export function changeEmailVerify(id, hash) {
	return {
		types: [
			CHANGE_EMAIL_VERYFICATION,
			CHANGE_EMAIL_VERYFICATION_SUCCESS,
			CHANGE_EMAIL_VERYFICATION_FAIL,
		],
		promise: (client) =>
			client.put(`/setting/change-email/verify`, {
				id: Number(id),
				hash,
			}),
	};
}

/**
 * Pobiera zablokowanych użytkowników
 * @api getBlockUsers
 * @param {Number} page numer strony
 */

export function getBlockedUsers(page) {
	return {
		types: [
			GET_BLOCKED_USERS,
			GET_BLOCKED_USERS_SUCCESS,
			GET_BLOCKED_USERS_FAIL,
		],
		promise: (client) =>
			client.get(`/user/block`, {
				params: {
					page,
				},
			}),
	};
}
