/* eslint-disable max-len */
import pathPatterns from "utils/pathPatterns";

import { initialQuery } from "containers/Users/initialQuery";

const routes = {
	contact: {
		key: "contact",
		path: "/contact",
		link: "/contact",
		meta: { serviceNavItem: true, serviceNavIndex: 5 },
	},
	terms: {
		key: "terms",
		path: "/terms",
		link: "/terms",
		meta: { serviceNavItem: true, serviceNavIndex: 3 },
	},
	privacy: {
		key: "privacy",
		path: "/privacy",
		link: "/privacy",
		meta: { serviceNavItem: true, serviceNavIndex: 4 },
	},
	support: {
		key: "support",
		path: "/support",
		link: "/support",
		meta: { serviceNavItem: true, serviceNavIndex: 2 },
	},
	nearby: {
		key: "nearby",
		path: "/nearby",
		link: "/nearby",
		meta: {
			icon: "location-arrow-solid",
			iconInactive: "location-arrow-regular",
			iconActive: "location-arrow-solid",
			mainNavItem: true,
			mainNavIndex: 4,
		},
	},
	home: { key: "home", path: "/", link: "/", meta: {} },
	mobile: {
		key: "app",
		path: "/app",
		link: "/app",
		meta: { serviceNavItem: true, serviceNavIndex: 1 },
	},
	modal: {
		key: "modal",
		path: "users/:login/:type/:contentId/:album?",
		meta: {},
	},
	news: {
		key: "news",
		path: "/news",
		link: "/news",
		meta: { serviceNavItem: true, serviceNavIndex: 0 },
	},
	rankings: {
		key: "rankings",
		path: ["/rankings", "/rankings/:rankingPeriod/:sex/:country/:region"],
		link: "/rankings",
		meta: {
			icon: "trophy",
			iconInactive: "trophy-regular",
			iconActive: "trophy",
			mainNavItem: true,
			mainNavIndex: 5,
		},
	},
	users: {
		key: "users",
		path: [
			"/search/preferences/:preference",
			"/search/:country?/:region?/:city?",
		],
		link: "/search",
		meta: {
			initialQuery,
			icon: "user-circle",
			iconActive: "user-circle",
			iconInactive: "user-circle-regular",
			mainNavItem: true,
			mainNavIndex: 0,
			additional: "online",
		},
	},
	user: { key: "user", path: "/users/:login", link: "/users/", meta: {} },
	userpanel: {
		key: "userpanel",
		path: "/userpanel",
		link: "/userpanel",
		meta: {},
	},
	gallery: {
		key: "galleries",
		path: `/galleries`,
		link: "/galleries",
		meta: {
			icon: "photo-video-solid",
			iconInactive: "photo-video-regular",
			iconActive: "photo-video-solid",
			mainNavItem: true,
			mainNavIndex: 1,
			transKey: "galleries",
		},
	},
	gelleryPhotos: {
		key: "galleryPhotos",
		path: `/galleries/photos`,
		link: "/galleries/photos",
		meta: {
			icon: "image-solid",
			iconInactive: "image-regular",
			iconActive: "image-solid",
			mainNavItem: true,
			mainNavIndex: 2,
			transKey: "images",
		},
	},
	gelleryVideos: {
		key: "galleryVideos",
		path: `/galleries/videos`,
		link: "/galleries/videos",
		meta: {
			icon: "film-alt-solid",
			iconInactive: "film-alt-regular",
			iconActive: "film-alt-solid",
			mainNavItem: true,
			mainNavIndex: 3,
			transKey: "videos",
		},
	},
	posts: {
		key: "posts",
		path: `/posts`,
		link: "/posts",
		meta: {
			icon: "align-left",
			iconInactive: "align-left-regular",
			iconActive: "align-left",
			mainNavItem: true,
			mainNavIndex: 6,
		},
	},
	activity: {
		key: "activity",
		path: `/activity/:date(${pathPatterns.dateYYYY_MM_DD})?`,
		link: "/activity",
		meta: {
			icon: "user-friends",
			iconInactive: "user-friends-regular",
			iconActive: "user-friends",
			mainNavItem: true,
			mainNavIndex: 4,
		},
	},
	payments: {
		key: "payments",
		path: "/payments",
		link: "/payments",
		meta: {},
	},
	notfound: { key: "notfound", path: "*", link: "*", meta: {} },
};

export default routes;

export const routesArray = Object.keys(routes).map((route) => ({
	...routes[route],
}));
