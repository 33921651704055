export default {
	page: `[0-9]+`,
	numberId: `[0-9]+`,
	sex: `any|[a-z-]+`,
	sexSingle: `any|woman|man|couple|transsexual`,
	age: `any|[0-9]+`,
	ageComparment: `any|18-20|21-25|26-30|31-35|36-40|41-50|51-60|61-and-more`,
	country: `any|[a-z-]+`,
	region: `any|[a-z-]+|[0-9]+`,
	city: `any|[a-z-]+|[0-9]+`,
	distance: `0|20|50|100|200`,
	content: `any|with_images|with_videos|online|meet|verified|vip|super_vip`,
	purpose: `any|cybersex|longer-acquaintance|flirt|love|friendship|real-meetings|affair|sex-without-commitment|im-searching-for-a-sponsor|i-will-sponsor|sexting`,
	sort: `online|latest|popular`,
	// only: `any|with_images|with_videos|online|online24|online30|meet|verified|vip|super_vip`,
	language: `any|[a-z-]+`,
	height: `any|[0-9]+`,
	figure: `any|slim|average|athletic|muscular|a-few-excess-kilograms|obese`,
	zodiac: `any|aries|taurus|gemini|cancer|leo|virgo|libra|scorpio|sagittarius|capricorn|aquarius|pisces`,
	hair: `any|bald|blond|brunet|brown|ginger|other`,
	relationship: `any|in-marriage|in-relationship|in-open-relationship|single`,
	kids: `any|have|do-not-have`,
	drinking: `any|like|drink-occasionally|dont-drink`,
	smoking: `any|like|smoke-occasionally|dont-smoke`,
	education: `any|primary|lower-secondary|vocational|secondary|higher`,
	rankingPeriod: `today|yesterday|day-before-yesterday|last-7-days|last-30-days|total`,
	period: `latest|total|[0-9]{2}-[0-9]{2}-[0-9]{4}|[0-9]{2}-[0-9]{4}|[0-9]{4}`,
	dateDD_MM_YYYY: `any|[0-9]{2}-[0-9]{2}-[0-9]{4}`,
	dateYYYY_MM_DD: `any|[0-9]{4}-[0-9]{2}-[0-9]{2}`,
};
