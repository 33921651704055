/* eslint-disable no-unused-vars */
import jwtDecode from "jwt-decode";
import api from "helpers/ApiClient";
import config from "configuration";
import cookie from "js-cookie";

const getNewToken = async (oldToken) => {
	try {
		const {
			data: { token },
		} = await api({
			method: "put",
			url: "/authorization/token",
			headers: {
				Authorization: `Bearer ${oldToken}`,
			},
		});
		cookie.set(
			config.auth.cookieName,
			token,
			config.auth.cookieOptionsJsCookie
		);
		return token;
	} catch {
		return null;
	}
};

export const checkTokenExpires = (token) =>
	new Promise((resolve) => {
		try {
			const { exp } = jwtDecode(token);
			const current = (new Date().getTime() + 1) / 1000;
			// jeżeli token ma wygasnąć za mniej niz 30 min
			if (Math.floor(exp - current) < 1800) {
				return resolve(getNewToken(token));
			}
		} catch (err) {
			if (token) {
				resolve(getNewToken(token));
			}
			resolve(null);
		}
		resolve(token);
	});

export const checkTokenIsExpired = (token) =>
	new Promise((resolve) => {
		try {
			const { exp } = jwtDecode(token);
			const current = (new Date().getTime() + 1) / 1000;
			// jeżeli token wygasł
			if (Math.floor(exp - current) <= 0) {
				return resolve(true);
			}
		} catch (err) {
			resolve(false);
		}

		resolve(false);
	});
