// ** Third Party Components
import axios from "axios";
import cookie from "js-cookie";
import url from "url";

// ** Configuration
import configGlobal from "configuration";

import { checkTokenIsExpired } from "./checkTokenExpires";

// #####################################################

const useProxy = JSON.parse(
	(process.env.RAZZLE_USE_PROXY || "false").toLowerCase()
);

const formatProxyUrl = () =>
	url.format(url.parse(process.env.RAZZLE_API_URL_PROXY, false));

const api = axios.create({
	baseURL: useProxy ? formatProxyUrl() : process.env.RAZZLE_API_URL,
	crossdomain: true,
});

const fingerprint = cookie.get(configGlobal.fingerprint.cookieName);

const authToken = cookie.get(configGlobal.auth.cookieName);

api.addAuthToken = (token) => {
	if (token) {
		cookie.set(configGlobal.auth.cookieName, token, {
			...configGlobal.auth.cookieOptionsJsCookie,
			expires: 365,
			secure: true,
		});

		api.defaults.headers.common.Authorization = `Bearer ${token}`;
	}
};

api.addFingerprint = (hash) => {
	if (hash) {
		cookie.set(configGlobal.fingerprint.cookieName, hash, {
			...configGlobal.fingerprint.cookieOptionsJsCookie,
			expires: 365,
			secure: true,
		});
		api.defaults.headers.common["Datezone-Fingerprint"] = hash;
	}
};

api.defaults.headers.common["Accept-Language"] = "pl"; // temporary
api.modifyLanguageHeader = (language) => {
	api.defaults.headers.common["Accept-Language"] = language;
};

api.getLanguage = () => {
	return api.defaults.headers.common["Accept-Language"];
};

api.defaults.headers.common["Accept"] = "application/api.1.0+json";
api.defaults.headers.common["Authorization"] = `Bearer ${authToken}`;

if (fingerprint) {
	api.defaults.headers.common["Datezone-Fingerprint"] = fingerprint;
}

api.interceptors.request.use((request) => {
	const authCookie = cookie.get(configGlobal.auth.cookieName);
	if (request.headers && authCookie) {
		request.headers["Authorization"] = `Bearer ${authCookie}`;
	}

	return request;
});

api.interceptors.response.use(
	async (response) => {
		const { status, data, headers, config } = response || {};

		if (headers && headers["datezone-user-ban"]) {
			import("./headers/updateSuspensionHeader").then(
				(updateSuspensionHeader) => {
					updateSuspensionHeader.default(headers);
				}
			);
		}

		/**
		 * Zapisanie danych o licznikach w Redux z headera
		 */
		if (headers && headers["datezone-counters"]) {
			import("./headers/counterHeader").then((counterHeader) => {
				counterHeader.default({
					headers,
					bNotificationNotUpdate:
						config.url === "/notification" ||
						config.url === "/notification/preference",
					bMessageUpdate:
						config.url === "/message" ||
						config.url.includes("/message/read"),
					withTimeout:
						window?.location?.pathname ===
						"/userpanel/notifications/list",
					noUpdate: config.url === "/merge/mainShort",
				});
			});
		}

		// ** Aktualizacja danych usera na podstawie danych przekazanych w nagłówku
		if (
			config.url !== "/authorization/logout" &&
			headers &&
			headers["datezone-user-data"]
		) {
			import("./headers/saveUserData").then((saveUserData) => {
				saveUserData.default(headers, config);
			});
		}

		if (
			!!headers.authorization &&
			config.url !== "/authorization/login/web"
		) {
			const [token] = headers.authorization
				.match(/Bearer\s(\S+)/)
				.slice(1);

			checkTokenIsExpired(token).then((tokenExpired) => {
				if (!tokenExpired) {
					api.addAuthToken(token);
				}
			});
		}

		if (status === 204 && data === "") {
			return {
				...response,
				data: null,
			};
		}

		if (data?.response) {
			return {
				...response,
				data: data.response,
			};
		}

		return {
			...response,
			data,
		};
	},
	(error) => {
		const { response } = error || {};

		if (response) {
			if (response?.headers && response.headers["datezone-user-ban"]) {
				import("./headers/updateSuspensionHeader").then(
					(updateSuspensionHeader) => {
						updateSuspensionHeader.default(response.headers);
					}
				);
			}

			if (response?.headers && response.headers["datezone-user-data"]) {
				import("./headers/saveUserData").then((saveUserData) => {
					saveUserData.default(response.headers);
				});
			}
		}

		return Promise.reject(error);
	}
);

export default api;
