const desiredOrder = ["woman", "man", "couple", "transsexual"];

export const searchSexCustomSort = (a, b) => {
	const indexA = desiredOrder.indexOf(a);
	const indexB = desiredOrder.indexOf(b);

	// Jeśli któryś z elementów nie istnieje w porządku docelowym, umieszczamy go na końcu
	if (indexA === -1) return 1;
	if (indexB === -1) return -1;

	// Porównujemy indeksy elementów w porządku docelowym
	return indexA - indexB;
};
