import { getOnlineStatus } from "libs/online-status";
import { logout } from "store/modules/auth";
import { setInitialState } from "store/reducer";
import api from "helpers/ApiClient";

export default function clientMiddleware(client) {
	return ({ dispatch, getState }) =>
		(next) =>
		(action) => {
			if (typeof action === "function") {
				return action(dispatch, getState);
			}

			const { promise, types, noToast, ...rest } = action;

			if (!promise) {
				return next(action);
			}

			const state = getState();

			// eslint-disable-next-line no-param-reassign
			if (state.global.fingerprint) {
				client.defaults.headers.common["Datezone-Fingerprint"] =
					state.global.fingerprint;
			}

			const [REQUEST, SUCCESS, FAILURE] = types;
			next({ ...rest, type: REQUEST });

			const actionPromise = promise(client)
				.then(
					(result) => {
						const { data } = result || {};

						const finalResult = {
							...result,
							data: data === "" ? null : data,
						};

						// ** Sprawdzenie czy w zwaracanej odpowiedzie znajdują się obiekty ze statusem '400'
						// ** (np. w przypadku bulka), gdy takie obiekty istnieją ustawiamy typ akcji 'FAILURE',
						if (data && typeof data === "object") {
							const valueIndex = Object.values(data).findIndex(
								(value) =>
									typeof value === "object" &&
									value?.status === 400
							);

							if (valueIndex !== -1) {
								const errorObject =
									Object.values(data)[valueIndex];

								import("helpers/toastWrapper").then(
									({ toast }) =>
										toast.error(`${errorObject.error}`)
								);
								return next({
									...rest,
									error: errorObject.error,
									type: FAILURE,
								});
							} else {
								return next({
									...rest,
									error: null,
									result: finalResult,
									type: SUCCESS,
								});
							}
						} else {
							return next({
								...rest,
								error: null,
								result: finalResult,
								type: SUCCESS,
							});
						}
					},
					(err) => {
						const { response } = err;

						let errorMessage = "";
						const errorString = err.response?.data?.error || null;
						if (getOnlineStatus() === false) {
							const errorMessageTranslation = {
								pl: "Brak połączenia z internetem.",
								cs: "Žádné internetové připojení.",
								de: "Keine Internetverbindung.",
								en: "No internet connection.",
								ru: "Нет соединения с интернетом.",
								uk: "Немає підключення до Інтернету.",
							};

							errorMessage =
								errorMessageTranslation[state.global.language];
						} else if (
							err?.status === 401 ||
							response?.status === 401
						) {
							dispatch(logout());
							dispatch(setInitialState());
							window.location = "/";
							return;
						} else if (response?.status === 409) {
							dispatch(logout());
							dispatch(setInitialState());
							window.location = "/";
							return;
						} else if (response?.status === 550) {
							const errorMessageTranslation = {
								pl: "Trwa aktualizacja oprogramowania...",
								cs: "Probíhá aktualizace softwaru...",
								de: "Software ist gegenwärtig aktualisiert...",
								en: "Updating software...",
								ru: "Обновление программного обеспечения...",
								uk: "Оновлення програмного забеспечення ...",
							};

							errorMessage =
								errorMessageTranslation[state.global.language];
						} else if (err?.status === 504) {
							const errorMessageTranslation = {
								pl: "Wystąpił problem komunikacji z serwerem - przekroczono limit czasu połączenia.",
								cs: "Při komunikaci se serverem došlo k problému – vypršel časový limit připojení.",
								de: "Es gab ein Problem bei der Kommunikation mit dem Server – Zeitüberschreitung bei der Verbindung.",
								en: "There was a problem communicating with the server - connection timed out.",
								ru: "Возникла проблема со связью с сервером - время ожидания соединения истекло.",
								uk: "Виникла проблема зв'язку із сервером – минув час очікування з'єднання.",
							};

							errorMessage =
								errorMessageTranslation[state.global.language];
						} else if (
							err.code === "ERR_CANCELED" ||
							err.message === "canceled" ||
							err.code === "ERR_NETWORK"
						) {
							return;
						} else if (
							(err.code === "ECONNABORTED" &&
								err.message.includes("timeout")) ||
							err.message.includes("timeout exceeded")
						) {
							const errorMessageTranslation = {
								pl: "Wystąpił problem komunikacji z serwerem - przekroczono limit czasu połączenia.",
								cs: "Při komunikaci se serverem došlo k problému – vypršel časový limit připojení.",
								de: "Es gab ein Problem bei der Kommunikation mit dem Server – Zeitüberschreitung bei der Verbindung.",
								en: "There was a problem communicating with the server - connection timed out.",
								ru: "Возникла проблема со связью с сервером - время ожидания соединения истекло.",
								uk: "Виникла проблема зв'язку із сервером – минув час очікування з'єднання.",
							};

							import("helpers/toastWrapper").then(({ toast }) =>
								toast.error(
									errorMessageTranslation[
										state.global.language
									]
								)
							);

							return;
						} else if (
							err.code === "ECONNABORTED" &&
							err.message.includes("Request aborted")
						) {
							return;
						} else if (!errorString) {
							const errorMessageTranslation = {
								pl: "Wystąpił nieznany błąd.",
								cs: "Nastala neznámá chyba.",
								de: "Ein unbekannter Fehler ist aufgetreten.",
								en: "An unknown error occurred.",
								ru: "Произошла неизвестная ошибка.",
								uk: "Сталася невідома помилка.",
							};

							errorMessage =
								errorMessageTranslation[state.global.language];

							const data = {
								route:
									window?.location?.href ??
									"FAILED_TO_GET_ROUTE",
								error: err?.message ?? "UNKNOWN ERROR",
								error_stack: err?.stack
									? err?.stack.toString()
									: "ERROR STACK EMPTY",
								from: "clientMiddleware - catch - nieznany błąd",
								error_full: err,
								error_message_response:
									err?.response?.data?.error ??
									"Wystąpił nieznany błąd",
							};
							api.post("/system/react-error", data);
						} else {
							errorMessage = `${JSON.stringify(errorString)}`;

							if (errorMessage[0] === '"') {
								errorMessage = errorMessage.slice(1, -1);
							}
						}

						// console.warn("MIDDLEWARE INSIDE ERROR:", {
						// 	err,
						// 	body,
						// 	headers,
						// 	errorMessage,
						// });

						if (!noToast) {
							import("helpers/toastWrapper").then(({ toast }) =>
								toast.error(errorMessage)
							);
						}

						next({
							...rest,
							error: errorString,
							type: FAILURE,
						});
					}
				)
				.catch((error) => {
					// console.warn("MIDDLEWARE CATCH ERROR:", error);

					const data = {
						route: window?.location?.href ?? "FAILED_TO_GET_ROUTE",
						error: error?.message ?? "UNKNOWN ERROR",
						error_stack: error?.stack.toString() ?? "",
						from: "clientMiddleware - catch",
						error_full: JSON.stringify(error),
						error_message_response:
							error?.response?.data?.error ??
							"Wystąpił nieznany błąd",
					};
					api.post("/system/react-error", data);

					// if (!noToast) toast.error(`${error}`);

					next({ ...rest, error, type: FAILURE });
				});

			return actionPromise;
		};
}
