// ** Third Party Components
import styled from "styled-components";

// #####################################################

export const StyledMessageContainer = styled.div`
	width: 100%;
	text-align: center;
	line-height: 2.5rem;
	margin: auto;
`;

// #####################################################
