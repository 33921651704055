// ** Third Party Components
import styled, { css } from "styled-components";

// #####################################################

export const StyledButtonContent = styled("div")`
	display: flex;
	gap: 0.5rem;
	${({ textLeft }) =>
		textLeft &&
		css`
			flex: 1;
		`}
	align-items: center;
	justify-content: ${({ textLeft }) => (textLeft ? "left" : "center")};

	${({ noPointerEffect }) =>
		noPointerEffect &&
		css`
			pointer-events: none;
		`}

	${({ alignItemsStart }) =>
		alignItemsStart &&
		css`
			align-items: flex-start;
		`}
`;

// #####################################################
