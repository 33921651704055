/* eslint-disable no-case-declarations */
import defaults from "../../defaults";

const GET_CURRENT = "friends/GET_CURRENT";
const GET_CURRENT_SUCCESS = "friends/GET_CURRENT_SUCCESS";
const GET_CURRENT_FAIL = "friends/GET_CURRENT_FAIL";

const REMOVE_CURRENT = "friends/REMOVE_CURRENT";
const REMOVE_CURRENT_SUCCESS = "friends/REMOVE_CURRENT_SUCCESS";
const REMOVE_CURRENT_FAIL = "friends/REMOVE_CURRENT_FAIL";

const GET_INVITATIONS = "friends/GET_INVITATIONS";
const GET_INVITATIONS_SUCCESS = "friends/GET_INVITATIONS_SUCCESS";
const GET_INVITATIONS_FAIL = "friends/GET_INVITATIONS_FAIL";

const ACCEPT_INVITATIONS = "friends/ACCEPT_INVITATIONS";
const ACCEPT_INVITATIONS_SUCCESS = "friends/ACCEPT_INVITATIONS_SUCCESS";
const ACCEPT_INVITATIONS_FAIL = "friends/ACCEPT_INVITATIONS_FAIL";

const REMOVE_INVITATIONS = "friends/REMOVE_INVITATIONS";
const REMOVE_INVITATIONS_SUCCESS = "friends/REMOVE_INVITATIONS_SUCCESS";
const REMOVE_INVITATIONS_FAIL = "friends/REMOVE_INVITATIONS_FAIL";

const GET_INVITATIONS_SEND = "friends/GET_INVITATIONS_SEND";
const GET_INVITATIONS_SEND_SUCCESS = "friends/GET_INVITATIONS_SEND_SUCCESS";
const GET_INVITATIONS_SEND_FAIL = "friends/GET_INVITATIONS_SEND_FAIL";

const REMOVE_INVITATIONS_SEND = "friends/REMOVE_INVITATIONS_SEND";
const REMOVE_INVITATIONS_SEND_SUCCESS =
	"friends/REMOVE_INVITATIONS_SEND_SUCCESS";
const REMOVE_INVITATIONS_SEND_FAIL = "friends/REMOVE_INVITATIONS_SEND_FAIL";

const GET_FOLLOWERS = "friends/GET_FOLLOWERS";
const GET_FOLLOWERS_SUCCESS = "friends/GET_FOLLOWERS_SUCCESS";
const GET_FOLLOWERS_FAIL = "friends/GET_FOLLOWERS_FAIL";

const REMOVE_FOLLOWERS = "friends/REMOVE_FOLLOWER";
const REMOVE_FOLLOWERS_SUCCESS = "friends/REMOVE_FOLLOWER_SUCCESS";
const REMOVE_FOLLOWERS_FAIL = "friends/REMOVE_FOLLOWER_FAIL";

const GET_FOLLOWING = "friends/GET_FOLLOWING";
const GET_FOLLOWING_SUCCESS = "friends/GET_FOLLOWING_SUCCESS";
const GET_FOLLOWING_FAIL = "friends/GET_FOLLOWING_FAIL";

const REMOVE_FOLLOWING = "friends/REMOVE_FOLLOWING";
const REMOVE_FOLLOWING_SUCCESS = "friends/REMOVE_FOLLOWING_SUCCESS";
const REMOVE_FOLLOWING_FAIL = "friends/REMOVE_FOLLOWING_FAIL";

const initialState = defaults.userpanel.friends;

export default function reducer(state = initialState, action = {}) {
	switch (action.type) {
		case GET_CURRENT:
			return {
				...state,
				current: {
					...state.current,
					loading: true,
				},
			};
		case GET_CURRENT_SUCCESS:
			return {
				...state,
				current: {
					loading: false,
					loaded: true,
					users: action.result.data?.users ?? [],
					pagination: action.result.data?.pagination ?? null,
				},
			};
		case GET_CURRENT_FAIL:
			return {
				...state,
				current: {
					...state.current,
					loading: false,
				},
			};
		case REMOVE_CURRENT:
			return {
				...state,
				current: {
					...state.current,
					whileRemoving: true,
				},
			};
		case REMOVE_CURRENT_SUCCESS:
			return {
				...state,
				current: {
					...state.current,
					whileRemoving: false,
					users: state.current.users.filter(
						(user) => !action.data.includes(user.id)
					),
				},
			};
		case REMOVE_CURRENT_FAIL:
			return {
				...state,
				current: {
					...state.current,
					whileRemoving: false,
				},
			};
		case GET_INVITATIONS:
			return {
				...state,
				invitations: {
					...state.invitations,
					loading: true,
				},
			};
		case GET_INVITATIONS_SUCCESS:
			return {
				...state,
				invitations: {
					loading: false,
					loaded: true,
					users: action.result.data?.users ?? [],
					pagination: action.result.data?.pagination ?? null,
				},
			};
		case GET_INVITATIONS_FAIL:
			return {
				...state,
				invitations: {
					...state.invitations,
					loading: false,
				},
			};
		case ACCEPT_INVITATIONS:
			return {
				...state,
				invitations: {
					...state.invitations,
					whileAccepting: true,
				},
			};
		case ACCEPT_INVITATIONS_SUCCESS:
			return {
				...state,
				invitations: {
					...state.invitations,
					whileAccepting: false,
					users: state.invitations.users.filter(
						(user) => !action.data.includes(user.id)
					),
				},
			};
		case ACCEPT_INVITATIONS_FAIL:
			return {
				...state,
				invitations: {
					...state.invitations,
					whileAccepting: false,
				},
			};
		case REMOVE_INVITATIONS:
			return {
				...state,
				invitations: {
					...state.invitations,
					whileRejecting: true,
				},
			};
		case REMOVE_INVITATIONS_SUCCESS:
			return {
				...state,
				invitations: {
					...state.invitations,
					whileRejecting: false,
					users: state.invitations.users.filter(
						(user) => !action.data.includes(user.id)
					),
				},
			};
		case REMOVE_INVITATIONS_FAIL:
			return {
				...state,
				invitations: {
					...state.invitations,
					whileRejecting: false,
				},
			};
		case GET_INVITATIONS_SEND:
			return {
				...state,
				invitationsSend: {
					...state.invitationsSend,
					loading: true,
				},
			};
		case GET_INVITATIONS_SEND_SUCCESS:
			return {
				...state,
				invitationsSend: {
					loading: false,
					loaded: true,
					users: action.result.data?.users ?? [],
					pagination: action.result.data?.pagination ?? null,
				},
			};
		case GET_INVITATIONS_SEND_FAIL:
			return {
				...state,
				invitationsSend: {
					...state.invitationsSend,
					loading: false,
				},
			};
		case GET_FOLLOWERS:
			return {
				...state,
				followers: {
					...state.followers,
					loading: true,
				},
			};
		case GET_FOLLOWERS_SUCCESS:
			return {
				...state,
				followers: {
					loading: false,
					loaded: true,
					users: action.result.data?.users ?? [],
					pagination: action.result.data?.pagination ?? null,
				},
			};
		case GET_FOLLOWERS_FAIL:
			return {
				...state,
				followers: {
					...state.followers,
					loading: false,
				},
			};
		case REMOVE_FOLLOWERS:
			return {
				...state,
				followers: {
					...state.followers,
					whileRemoving: true,
				},
			};
		case REMOVE_FOLLOWERS_SUCCESS:
			return {
				...state,
				followers: {
					...state.followers,
					whileRemoving: false,
					users: state.followers.users.filter(
						(user) => !action.data.includes(user.id)
					),
				},
			};
		case REMOVE_FOLLOWERS_FAIL:
			return {
				...state,
				followers: {
					...state.followers,
					whileRemoving: false,
				},
			};
		case GET_FOLLOWING:
			return {
				...state,
				following: {
					...state.following,
					loading: true,
				},
			};
		case GET_FOLLOWING_SUCCESS:
			return {
				...state,
				following: {
					loading: false,
					loaded: true,
					total: action.result.data?.total ?? 0,
					users: action.result.data?.users ?? [],
					pagination: action.result.data?.pagination ?? null,
				},
			};
		case GET_FOLLOWING_FAIL:
			return {
				...state,
				following: {
					...state.following,
					loading: false,
				},
			};
		case REMOVE_FOLLOWING:
			return {
				...state,
				following: {
					...state.following,
					whileRemoving: true,
				},
			};
		case REMOVE_FOLLOWING_SUCCESS:
			return {
				...state,
				following: {
					...state.following,
					whileRemoving: false,
					users: state.following.users.filter(
						(user) => !action.data.includes(user.id)
					),
				},
			};
		case REMOVE_FOLLOWING_FAIL:
			return {
				...state,
				following: {
					...state.following,
					whileRemoving: false,
				},
			};
		default:
			return state;
	}
}

/**
 * Zwraca listę aktualnych znajomych
 * @api GET getUserFriends
 * @param {Number} userId identyfikator użytkownika
 * @param {Number} page numer strony
 */
export function getCurrent(userId, page) {
	return {
		types: [GET_CURRENT, GET_CURRENT_SUCCESS, GET_CURRENT_FAIL],
		promise: (client) =>
			client.get(`/friend`, {
				params: {
					id: userId,
					page,
				},
			}),
	};
}

/**
 * Usuwa użytkownikó z listy znajomych
 * @api DELETE deleteUserFromFriendMultiple
 * @param {Array.<Number>} ids tablica identyfikatorów użytkowników
 */
export function removeCurrents(ids) {
	return {
		types: [REMOVE_CURRENT, REMOVE_CURRENT_SUCCESS, REMOVE_CURRENT_FAIL],
		promise: (client) =>
			client.delete(`/friend/multiple`, {
				params: {
					ids: ids.join(","),
				},
			}),
		data: ids,
	};
}

/**
 * Zwraca listę użytkowników, od których otrzymao zaproszenie do znajomych
 * @api GET getUserInvitations
 * @param {Number} page numer strony
 */
export function getInvitations(page) {
	return {
		types: [GET_INVITATIONS, GET_INVITATIONS_SUCCESS, GET_INVITATIONS_FAIL],
		promise: (client) =>
			client.get(`/friend/invitation`, {
				params: {
					page,
				},
			}),
	};
}

/**
 * Akceptuje od użytkowników zaproszenie do znajomych
 * @api PUT acceptUserInvitingMultiple
 * @param {Array.<Number>} ids tablica identyfikatorów użytkowników
 */
export function acceptInvitations(ids) {
	return {
		types: [
			ACCEPT_INVITATIONS,
			ACCEPT_INVITATIONS_SUCCESS,
			ACCEPT_INVITATIONS_FAIL,
		],
		promise: (client) =>
			client.put(`/friend/invitation/multiple`, null, {
				params: {
					ids: ids.join(","),
				},
			}),
		data: ids,
	};
}

/**
 * Odrzuca od użytkowników zaproszenie do znajomych
 * @api DELETE deleteUserInvitingMultiple
 * @param {Number} page numer strony
 */
export function rejectInvitations(ids) {
	return {
		types: [
			REMOVE_INVITATIONS,
			REMOVE_INVITATIONS_SUCCESS,
			REMOVE_INVITATIONS_FAIL,
		],
		promise: (client) =>
			client.delete(`/friend/invitation/multiple`, {
				params: {
					ids: ids.join(","),
				},
			}),
		data: ids,
	};
}

/**
 * Zwraca listę użytkowników do których wysłano zaproszenie do znajomych
 * @api GET getUserInvitationsSend
 * @param {Number} page numer strony
 */
export function getInvitationsSend(page) {
	return {
		types: [
			GET_INVITATIONS_SEND,
			GET_INVITATIONS_SEND_SUCCESS,
			GET_INVITATIONS_SEND_FAIL,
		],
		promise: (client) =>
			client.get(`/friend/invitation-send`, {
				params: {
					page,
				},
			}),
	};
}

/**
 * Usuwa (wycofuje) wysłane do użytkowników zaproszenia do znajomych
 * @api DELETE deleteUserInvitingSendMultiple
 * @param {Array.<Number>} ids tablica identyfikatorów użytkowników
 */
export function cancelInvitationsSend(ids) {
	return {
		types: [
			REMOVE_INVITATIONS_SEND,
			REMOVE_INVITATIONS_SEND_SUCCESS,
			REMOVE_INVITATIONS_SEND_FAIL,
		],
		promise: (client) =>
			client.delete(`/friend/invitation-send/multiple`, {
				params: {
					ids: ids.join(","),
				},
			}),
		data: ids,
	};
}

/**
 * Zwraca listę obserwowanych użytkowników
 * @api GET getUserFollowersSend
 * @param {Number} userId identyfikator użytkownika
 * @param {Number} page numer strony
 */
export function getFollowing(userId, page) {
	return {
		types: [GET_FOLLOWING, GET_FOLLOWING_SUCCESS, GET_FOLLOWING_FAIL],
		promise: (client) =>
			client.get(`/friend/follow-send`, {
				params: {
					id: userId,
					page,
				},
			}),
	};
}

/**
 * Usuwa użytkowników z listy obserwowanych
 * @api DELETE deleteUserFromFollowersSendMultiple
 * @param {Array.<Number>} ids tablica identyfikatorów użytkowników
 */
export function removeFollowings(ids) {
	return {
		types: [
			REMOVE_FOLLOWING,
			REMOVE_FOLLOWING_SUCCESS,
			REMOVE_FOLLOWING_FAIL,
		],
		promise: (client) =>
			client.delete(`/friend/follow-send/multiple`, {
				params: {
					ids: ids.join(","),
				},
			}),
		data: ids,
	};
}

/**
 * Zwraca listę użytkowników obserwujących
 * @api GET getUserFollowers
 * @param {Number} userId identyfikator użytkownika
 * @param {Number} page numer strony
 */
export function getFollowers(userId, page) {
	return {
		types: [GET_FOLLOWERS, GET_FOLLOWERS_SUCCESS, GET_FOLLOWERS_FAIL],
		promise: (client) =>
			client.get(`/friend/follow`, {
				params: {
					id: userId,
					page,
				},
			}),
	};
}

/**
 * Usuwa użytkowników z listy obserwująych
 * @api DELETE deleteUserFromFollowerMultiple
 * @param {Array.<Number>} ids tablica identyfikatorów użytkowników
 */
export function removeFollowers(ids) {
	return {
		types: [
			REMOVE_FOLLOWERS,
			REMOVE_FOLLOWERS_SUCCESS,
			REMOVE_FOLLOWERS_FAIL,
		],
		promise: (client) =>
			client.delete(`/friend/follow/multiple`, {
				params: {
					ids: ids.join(","),
				},
			}),
		data: ids,
	};
}
