import defaults from "../../defaults";

const GET_POSTS = "userpanel/posts/GET_POSTS";
const GET_POSTS_SUCCESS = "userpanel/posts/GET_POSTS_SUCCESS";
const GET_POSTS_FAIL = "userpanel/posts/GET_POSTS_FAIL";

const DELETE_POSTS = "userpanel/posts/DELETE_POSTS";
const DELETE_POSTS_SUCCESS = "userpanel/posts/DELETE_POSTS_SUCCESS";
const DELETE_POSTS_FAIL = "userpanel/posts/DELETE_POSTS_FAIL";

const ADD_POST = "userpanel/posts/ADD_POST";
const ADD_POST_SUCCESS = "userpanel/posts/ADD_POST_SUCCESS";
const ADD_POST_FAIL = "userpanel/posts/ADD_POST_FAIL";

const GET_EDIT_POST_INFO = "userpanel/posts/GET_EDIT_POST_INFO";
const GET_EDIT_POST_INFO_SUCCESS = "userpanel/posts/GET_EDIT_POST_INFO_SUCCESS";
const GET_EDIT_POST_INFO_FAIL = "userpanel/posts/GET_EDIT_POST_INFO_FAIL";

const GET_POSTS_COMMENTS = "userpanel/posts/GET_POSTS_COMMENTS";
const GET_POSTS_COMMENTS_SUCCESS = "userpanel/posts/GET_POSTS_COMMENTS_SUCCESS";
const GET_POSTS_COMMENTS_FAIL = "userpanel/posts/GET_POSTS_COMMENTS_FAIL";

const GET_POSTS_LIKES = "userpanel/posts/GET_POSTS_LIKES";
const GET_POSTS_LIKES_SUCCESS = "userpanel/posts/GET_POSTS_LIKES_SUCCESS";
const GET_POSTS_LIKES_FAIL = "userpanel/posts/GET_POSTS_LIKES_FAIL";

const initialState = defaults.userpanel.posts;

export default function reducer(state = initialState, action = {}) {
	switch (action.type) {
		case GET_POSTS:
			return {
				...state,
				listing: {
					...state.listing,
					loading: true,
				},
			};
		case GET_POSTS_SUCCESS:
			return {
				...state,
				listing: {
					...state.listing,
					loading: false,
					loaded: true,
					success: true,
					error: false,
					...(action.result.data
						? {
								data: action.result.data.posts || [],
								pagination: {
									totalpages:
										action.result.data.pagination
											.totalpages,
									currentpage:
										action.result.data.pagination
											.currentpage,
								},
						  }
						: {
								data: initialState.listing.data,
								pagination: initialState.listing.pagination,
						  }),
				},
			};
		case GET_POSTS_FAIL:
			return {
				...state,
				listing: {
					...initialState.listing,
					loading: false,
					loaded: true,
					error: true,
				},
			};
		case DELETE_POSTS:
			return {
				...state,
				listing: {
					...state.listing,
					loading: true,
				},
			};
		case DELETE_POSTS_SUCCESS:
			return {
				...state,
				listing: {
					...state.listing,
					loading: false,
					loaded: true,
					success: true,
					error: false,
					data: state.listing.data.filter(
						({ id }) => !action.ids.includes(id)
					),
				},
			};
		case DELETE_POSTS_FAIL:
			return {
				...state,
				listing: {
					...state.listing,
					loading: false,
					loaded: true,
					error: true,
				},
			};
		case GET_EDIT_POST_INFO:
			return {
				...state,
				editPost: {
					...state.editPost,
					info: {
						...initialState.editPost.info,
						loading: true,
					},
					likes: {
						...initialState.editPost.likes,
						loading: true,
					},
					comments: {
						...initialState.editPost.comments,
						loading: true,
					},
				},
			};
		case GET_EDIT_POST_INFO_SUCCESS:
			return {
				...state,
				editPost: {
					...state.editPost,
					info: {
						...state.editPost.info,
						loading: false,
						loaded: true,
						success: true,
						accepted: action.result.data.accepted,
						accepted_description:
							action.result.data.accepted_description,
						text: action.result.data.text,
						text_raw: action.result.data.text_raw,
						text_edit: action.result.data.text_edit || [],
						private: action.result.data.private,
						date_add: action.result.data.date_add,
						count_like: action.result.data.likes,
						count_comment: action.result.data.comments,
						i_liked: action.result.data?.i_liked || false,
						i_commented: action.result.data?.i_comment || false,
					},
					likes: {
						...state.editPost.likes,
						loading: false,
						loaded: true,
						success: true,
						...action.result.data.likes_list,
					},
					comments: {
						...state.editPost.comments,
						loading: false,
						loaded: true,
						success: true,
						...action.result.data.comments_list,
					},
				},
			};
		case GET_EDIT_POST_INFO_FAIL:
			return {
				...state,
				editPost: {
					...state.editPost,
					info: {
						...state.editPost.info,
						loading: false,
						loaded: true,
						error: true,
					},
					likes: {
						...state.editPost.likes,
						loading: false,
						loaded: true,
						error: true,
					},
					comments: {
						...state.editPost.comments,
						loading: false,
						loaded: true,
						error: true,
					},
				},
			};
		case GET_POSTS_COMMENTS:
			return {
				...state,
				editPost: {
					...state.editPost,
					comments: {
						...state.editPost.comments,
						loading: true,
					},
				},
			};
		case GET_POSTS_COMMENTS_SUCCESS:
			return {
				...state,
				editPost: {
					...state.editPost,
					comments: {
						...state.editPost.comments,
						loading: false,
						loaded: true,
						success: true,
						comments: action.result.data.comments,
					},
				},
			};
		case GET_POSTS_COMMENTS_FAIL:
			return {
				...state,
				editPost: {
					...state.editPost,
					comments: {
						...state.editPost.comments,
						loading: false,
						loaded: true,
						error: true,
					},
				},
			};
		case GET_POSTS_LIKES:
			return {
				...state,
				editPost: {
					...state.editPost,
					likes: {
						...state.editPost.likes,
						loading: true,
					},
				},
			};
		case GET_POSTS_LIKES_SUCCESS:
			return {
				...state,
				editPost: {
					...state.editPost,
					likes: {
						...state.editPost.likes,
						loading: false,
						loaded: true,
						success: true,
						likes: action.result.data.likes,
					},
				},
			};
		case GET_POSTS_LIKES_FAIL:
			return {
				...state,
				editPost: {
					...state.editPost,
					likes: {
						...state.editPost.likes,
						loading: false,
						loaded: true,
						error: true,
					},
				},
			};
		default:
			return state;
	}
}

export function getPosts(page = 1) {
	return {
		types: [GET_POSTS, GET_POSTS_SUCCESS, GET_POSTS_FAIL],
		promise: (client) =>
			client.get(`/post/user`, {
				params: {
					page,
				},
			}),
	};
}

export function deletePosts(ids) {
	return {
		types: [DELETE_POSTS, DELETE_POSTS_SUCCESS, DELETE_POSTS_FAIL],
		promise: (client) =>
			client.delete(`/post/multiple`, {
				params: {
					ids: ids.join(","),
				},
			}),
		ids,
	};
}

export function addPost(text, isPrivate = false) {
	return {
		types: [ADD_POST, ADD_POST_SUCCESS, ADD_POST_FAIL],
		promise: (client) => client.post("/post", { text, private: isPrivate }),
		text,
	};
}

/**
 * Pobiera informacje o poście
 * @api GET getSinglePost
 * @param {Number} postId id postu
 * @returns
 */
export function getEditPostInfo(postId) {
	return {
		types: [
			GET_EDIT_POST_INFO,
			GET_EDIT_POST_INFO_SUCCESS,
			GET_EDIT_POST_INFO_FAIL,
		],
		promise: (client) =>
			client.get(`/post`, {
				params: { id: postId, additional: true },
			}),
	};
}

/**
 * Pobiera liste polubien do postu
 * @api GET getLikesOnFile
 * @param {Number} postId id postu
 * @param {Number} page numer strony
 */
export function getPostLikes(userId, postId, page) {
	return {
		types: [GET_POSTS_LIKES, GET_POSTS_LIKES_SUCCESS, GET_POSTS_LIKES_FAIL],
		promise: (client) =>
			client.get(`/like/file`, {
				params: {
					id: postId,
					user: userId,
					type: "post",
					page,
				},
			}),
	};
}

/**
 * Pobiera liste komentarzy do postu
 * @api GET getCommentsOnFile
 * @param {Number} postId id postu
 * @param {Number} page numer strony
 */
export function getPostComments(userId, postId, page) {
	return {
		types: [
			GET_POSTS_COMMENTS,
			GET_POSTS_COMMENTS_SUCCESS,
			GET_POSTS_COMMENTS_FAIL,
		],
		promise: (client) =>
			client.get(`/comment/file`, {
				params: {
					id: postId,
					user: userId,
					type: "post",
					page,
				},
			}),
	};
}
