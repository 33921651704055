/* eslint-disable prefer-template */
/* eslint-disable no-case-declarations */
import defaults from "../../defaults";

const GET_PHOTOS = "userpanel/photos/GET_PHOTOS";
const GET_PHOTOS_SUCCESS = "userpanel/photos/GET_PHOTOS_SUCCESS";
const GET_PHOTOS_FAIL = "userpanel/photos/GET_PHOTOS_FAIL";

const REMOVE_PHOTOS = "userpanel/photos/REMOVE_PHOTOS";
const REMOVE_PHOTOS_SUCCESS = "userpanel/photos/REMOVE_PHOTOS_SUCCESS";
const REMOVE_PHOTOS_FAIL = "userpanel/photos/REMOVE_PHOTOS_FAIL";

const GET_PHOTO_INFO = "userpanel/photos/GET_PHOTO_INFO";
const GET_PHOTO_INFO_SUCCESS = "userpanel/photos/GET_PHOTO_INFO_SUCCESS";
const GET_PHOTO_INFO_FAIL = "userpanel/photos/GET_PHOTO_INFO_FAIL";

const GET_PHOTO_COMMENTS = "userpanel/photos/GET_PHOTO_COMMENTS";
const GET_PHOTO_COMMENTS_SUCCESS =
	"userpanel/photos/GET_PHOTO_COMMENTS_SUCCESS";
const GET_PHOTO_COMMENTS_FAIL = "userpanel/photos/GET_PHOTO_COMMENTS_FAIL";

const GET_PHOTO_LIKES = "userpanel/photos/GET_PHOTO_LIKES";
const GET_PHOTO_LIKES_SUCCESS = "userpanel/photos/GET_PHOTO_LIKES_SUCCESS";
const GET_PHOTO_LIKES_FAIL = "userpanel/photos/GET_PHOTO_LIKES_FAIL";

const initialState = defaults.userpanel.photos;

export default function reducer(state = initialState, action = {}) {
	switch (action.type) {
		case GET_PHOTOS:
			return {
				...state,
				photos: {
					...state.photos,
					loading: true,
				},
			};
		case GET_PHOTOS_SUCCESS:
			return {
				...state,
				photos: {
					...state.photos,
					loading: false,
					error: false,
					success: true,
					photos: action.result.data?.photos ?? [],
					pagination: action.result.data?.pagination ?? {},
				},
			};
		case GET_PHOTOS_FAIL:
			return {
				...state,
				photos: {
					...state.photos,
					loading: false,
					error: true,
					success: false,
				},
			};
		case GET_PHOTO_INFO:
			return {
				...state,
				editPhoto: {
					...state.editPhoto,
					info: {
						...initialState.editPhoto.info,
						loading: true,
					},
					likes: {
						...initialState.editPhoto.likes,
						loading: true,
					},
					comments: {
						...initialState.editPhoto.comments,
						loading: true,
					},
				},
			};
		case GET_PHOTO_INFO_SUCCESS:
			return {
				...state,
				editPhoto: {
					...state.editPhoto,
					info: {
						...state.editPhoto.info,
						loading: false,
						loaded: true,
						success: true,
						accepted: action.result.data.accepted,
						accepted_description:
							action.result.data.accepted_description,
						image: action.result.data.images.big,
						description: action.result.data.description,
						description_raw: action.result.data.description_raw,
						description_edit:
							action.result.data?.description_edit || [],
						album: action.result.data.album,
						album_name: action.result.data.album_name,
						private: action.result.data.private,
						photos_in_album: action.result.data.photos_in_album,
						media_in_album: action.result.data?.media_in_album || 0,
						date_add: action.result.data.date_add,
						count_like: action.result.data.count_like,
						count_comment: action.result.data.count_comment,
						i_liked: action.result.data?.i_liked || false,
						i_commented: action.result.data?.i_comment || false,
					},
					likes: {
						...state.editPhoto.likes,
						loading: false,
						loaded: true,
						success: true,
						...action.result.data.likes,
					},
					comments: {
						...state.editPhoto.comments,
						loading: false,
						loaded: true,
						success: true,
						...action.result.data.comments,
					},
				},
			};
		case GET_PHOTO_INFO_FAIL:
			return {
				...state,
				editPhoto: {
					...state.editPhoto,
					info: {
						...state.editPhoto.info,
						loading: false,
						loaded: true,
						error: true,
					},
					likes: {
						...state.editPhoto.likes,
						loading: false,
						loaded: true,
						error: true,
					},
					comments: {
						...state.editPhoto.comments,
						loading: false,
						loaded: true,
						error: true,
					},
				},
			};
		case GET_PHOTO_COMMENTS:
			return {
				...state,
				editPhoto: {
					...state.editPhoto,
					comments: {
						...state.editPhoto.comments,
						loading: true,
					},
				},
			};
		case GET_PHOTO_COMMENTS_SUCCESS:
			return {
				...state,
				editPhoto: {
					...state.editPhoto,
					comments: {
						...state.editPhoto.comments,
						loading: false,
						loaded: true,
						success: true,
						comments: action.result.data.comments,
					},
				},
			};
		case GET_PHOTO_COMMENTS_FAIL:
			return {
				...state,
				editPhoto: {
					...state.editPhoto,
					comments: {
						...state.editPhoto.comments,
						loading: false,
						loaded: true,
						error: true,
					},
				},
			};
		case GET_PHOTO_LIKES:
			return {
				...state,
				editPhoto: {
					...state.editPhoto,
					likes: {
						...state.editPhoto.likes,
						loading: true,
					},
				},
			};
		case GET_PHOTO_LIKES_SUCCESS:
			return {
				...state,
				editPhoto: {
					...state.editPhoto,
					likes: {
						...state.editPhoto.likes,
						loading: false,
						loaded: true,
						success: true,
						likes: action.result.data.likes,
					},
				},
			};
		case GET_PHOTO_LIKES_FAIL:
			return {
				...state,
				editPhoto: {
					...state.editPhoto,
					likes: {
						...state.editPhoto.likes,
						loading: false,
						loaded: true,
						error: true,
					},
				},
			};
		default:
			return state;
	}
}

/**
 * Zwraca listing wiadomości zależnych od typu
 * @api GET getPhotos
 * @param {Number} page numer strony
 */
export function getPhotos(page) {
	return {
		types: [GET_PHOTOS, GET_PHOTOS_SUCCESS, GET_PHOTOS_FAIL],
		promise: (client) =>
			client.get(`/photo/panel`, {
				params: {
					type: "any",
					addEmpty: true,
					page,
				},
			}),
	};
}

/**
 * Usuwa zdjęcie z listy zdjęć użytkownika
 * @api DELETE deletePhotosMultiple
 * @param {Array.<Number>} ids tablica identyfikatorów zdjęć
 */
export function removePhotos(ids) {
	return {
		types: [REMOVE_PHOTOS, REMOVE_PHOTOS_SUCCESS, REMOVE_PHOTOS_FAIL],
		promise: (client) =>
			client.delete(`/photo/multiple`, {
				params: {
					ids: ids.join(","),
				},
			}),
		data: ids,
	};
}

/**
 * Pobiera informacje o zdjęciu
 * @api GET getPhotoById
 * @param {Number} photoId id zdjecia
 * @param {Number} userId id usera
 */
export function getPhotoInfo(photoId, userId) {
	return {
		types: [GET_PHOTO_INFO, GET_PHOTO_INFO_SUCCESS, GET_PHOTO_INFO_FAIL],
		promise: (client) =>
			client.get(`/photo/panel/single`, {
				params: { id: photoId, user: userId, additional: true },
			}),
	};
}

/**
 * Pobiera liste polubien do zdjecia
 * @api GET getLikesOnFile
 * @param {Number} photoId id zdjecia
 * @param {Number} page numer strony
 */
export function getPhotoLikes(userId, photoId, page) {
	return {
		types: [GET_PHOTO_LIKES, GET_PHOTO_LIKES_SUCCESS, GET_PHOTO_LIKES_FAIL],
		promise: (client) =>
			client.get(`/like/file`, {
				params: {
					id: photoId,
					user: userId,
					type: "photo",
					page,
				},
			}),
	};
}

/**
 * Pobiera liste komentarzy do zdjecia
 * @api GET getCommentsOnFile
 * @param {Number} photoId id zdjecia
 * @param {Number} page numer strony
 */
export function getPhotoComments(userId, photoId, page) {
	return {
		types: [
			GET_PHOTO_COMMENTS,
			GET_PHOTO_COMMENTS_SUCCESS,
			GET_PHOTO_COMMENTS_FAIL,
		],
		promise: (client) =>
			client.get(`/comment/file`, {
				params: {
					id: photoId,
					user: userId,
					type: "photo",
					page,
				},
			}),
	};
}
