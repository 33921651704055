import defaults from "../../defaults";

const GET_LINKS = "userpanel/LINKS/GET_LINKS";
const GET_LINKS_SUCCESS = "userpanel/LINKS/GET_LINKS_SUCCESS";
const GET_LINKS_FAIL = "userpanel/LINKS/GET_LINKS_FAIL";

const DELETE_LINKS = "userpanel/LINKS/DELETE_LINKS";
const DELETE_LINKS_SUCCESS = "userpanel/LINKS/DELETE_LINKS_SUCCESS";
const DELETE_LINKS_FAIL = "userpanel/LINKS/DELETE_LINKS_FAIL";

const ADD_LINK = "userpanel/links/ADD_LINK";
const ADD_LINK_SUCCESS = "userpanel/links/ADD_LINK_SUCCESS";
const ADD_LINK_FAIL = "userpanel/links/ADD_LINK_FAIL";

const CHANGE_LINK = "userpanel/links/CHANGE_LINK";
const CHANGE_LINK_SUCCESS = "userpanel/links/CHANGE_LINK_SUCCESS";
const CHANGE_LINK_FAIL = "userpanel/links/CHANGE_LINK_FAIL";

const initialState = defaults.userpanel.links;

export default function reducer(state = initialState, action = {}) {
	switch (action.type) {
		case GET_LINKS:
			return {
				...state,
				listing: {
					...state.listing,
					loading: true,
				},
			};
		case GET_LINKS_SUCCESS:
			return {
				...state,
				listing: {
					...state.listing,
					loading: false,
					loaded: true,
					success: true,
					error: false,
					...(action.result.data
						? {
								data: action.result.data.links || [],
								pagination: {
									totalpages:
										action.result.data.pagination
											.totalpages,
									currentpage:
										action.result.data.pagination
											.currentpage,
								},
						  }
						: {
								data: initialState.listing.data,
								pagination: initialState.listing.pagination,
						  }),
				},
			};
		case GET_LINKS_FAIL:
			return {
				...state,
				listing: {
					...initialState.listing,
					loading: false,
					loaded: true,
					error: true,
				},
			};
		case DELETE_LINKS:
			return {
				...state,
				listing: {
					...state.listing,
					loading: true,
				},
			};
		case DELETE_LINKS_SUCCESS:
			return {
				...state,
				listing: {
					...state.listing,
					loading: false,
					loaded: true,
					success: true,
					error: false,
					data: state.listing.data.filter(
						({ id }) => !action.ids.includes(id)
					),
				},
			};
		case DELETE_LINKS_FAIL:
			return {
				...state,
				listing: {
					...state.listing,
					loading: false,
					loaded: true,
					error: true,
				},
			};
		default:
			return state;
	}
}

export function getLinks(page = 1) {
	return {
		types: [GET_LINKS, GET_LINKS_SUCCESS, GET_LINKS_FAIL],
		promise: (client) =>
			client.get(`/user/allowed-link`, {
				params: {
					page,
				},
			}),
	};
}

export function changeLink(id, link, type) {
	return {
		types: [CHANGE_LINK, CHANGE_LINK_SUCCESS, CHANGE_LINK_FAIL],
		promise: (client) =>
			client.put(`user/allowed-link`, { id, link, type }),
		link,
	};
}

export function addLink(link, type) {
	return {
		types: [ADD_LINK, ADD_LINK_SUCCESS, ADD_LINK_FAIL],
		promise: (client) => client.post(`user/allowed-link`, { link, type }),
		link,
	};
}

export function deleteLinks(ids) {
	return {
		types: [DELETE_LINKS, DELETE_LINKS_SUCCESS, DELETE_LINKS_FAIL],
		promise: (client) =>
			client.delete(`/user/allowed-link/multiple`, {
				params: {
					ids: ids.join(","),
				},
			}),
		ids,
	};
}
