import defaults from "../../defaults";

const GET_PAYMENT_INFO = "payment/PAYMENT_INFO";
const GET_PAYMENT_INFO_SUCCESS = "payment/PAYMENT_INFO_SUCCESS";
const GET_PAYMENT_INFO_FAIL = "payment/PAYMENT_INFO_FAIL";

const initialState = defaults.userpanel.payment;

export default function reducer(state = initialState, action = {}) {
	switch (action.type) {
		case GET_PAYMENT_INFO:
			return {
				...state,
				info: {
					...state.info,
					loading: true,
				},
			};
		case GET_PAYMENT_INFO_SUCCESS: {
			const vip = Object.assign({}, action.result.data.vip);
			const superVip = Object.assign({}, action.result.data.super_vip);

			return {
				...state,
				info: {
					...state.info,
					loading: false,
					isPl: action.result.data.user.is_pl,
					currentStatus: action.result.data.user.vip,
					amount: {
						vip: vip || [],
						superVip: superVip || [],
					},
					left: {
						date: action.result.data?.user?.left?.date ?? null,
						time: action.result.data?.user?.left?.time ?? null,
					},
				},
			};
		}
		case GET_PAYMENT_INFO_FAIL:
			return {
				...state,
				info: {
					...state.info,
					loading: false,
				},
			};
		default:
			return state;
	}
}

export function getPaymentOptions() {
	return {
		types: [
			GET_PAYMENT_INFO,
			GET_PAYMENT_INFO_SUCCESS,
			GET_PAYMENT_INFO_FAIL,
		],
		promise: (client) =>
			client.get(`/payment`, {
				headers: {
					Accept: "application/api.1.1+json",
				},
			}),
	};
}
