import { BrowserRouter } from "react-router-dom";
import { loadableReady } from "@loadable/component";
import { hydrateRoot } from "react-dom/client";
import { useSSR } from "react-i18next";
import { Provider } from "react-redux";

import { createClientStoreFromState } from "store/vendor";
import { createBrowserHistory } from "history";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import Fallback from "components/fallback/Fallback";

const BaseApp = () => {
	useSSR(window.initialI18nStore, window.initialLanguage);

	const history = createBrowserHistory();
	const store = createClientStoreFromState(window.__PRELOADED_STATE__, {
		history,
	});

	return (
		<Fallback>
			<Provider store={store} key="provider">
				<BrowserRouter>
					<App />
				</BrowserRouter>
			</Provider>
		</Fallback>
	);
};

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

loadableReady(() => {
	const container = document.getElementById("root");
	hydrateRoot(container, <BaseApp />);
});

if (module.hot) {
	module.hot.accept();
}
